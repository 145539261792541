import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Connect from './common/connect';
import Main from './Pages/Main';
function App() {
  return (
    <div className="App" style={{width: '100%', minHeight:'95vh'}}>
      <Main />
      {/* <BrowserRouter>
        <Routes>
          <Route exact path="/"  element={<Navigate replace to ="/main"/>} />
          <Route path="/main" element={Main}/>
        </Routes>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
